html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:-webkit-any-link {
  color: #ffffff;
}

a:-webkit-any-link:hover {
  opacity: 0.8;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px 6px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px 6px rgba(180, 180, 180, 0.5);
  border-radius: 6px;
  border: 2px solid transparent;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-resizer {
  background-color: transparent;
}

ul.bn-notify-custom.bn-notify-notifications {
  display: none;
}
